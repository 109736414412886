import React from "react";
import moment from "moment";

const ConversationCard = ({
  memberId,
  name,
  timestamp,
  type,
  conversation,
  currentUser,
  viewChat,
}) => {
  function toDateTime(secs) {
    let date = moment.utc(secs.seconds * 1000).format("MMM. DD, yyyy hh:mm a");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(date).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }

  return (
    <>
      <div
        className="px-2 cursor-pointer"
        onClick={() =>
          viewChat({
            memberId,
            name,
            timestamp,
            type,
            conversation,
          })
        }
      >
        { type !== 'ATTACHMENT' &&
          <div className="w-auto border-t-2 py-4 px-2 flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <p className="text-xl font-bold text-primary">
                {name} {conversation.userId === currentUser ? "( Me )" : ""}{" "}
              </p>
              <p className="text-xs text-gray-400">{toDateTime(timestamp)}</p>
            </div>
            <div className="flex flex-row">
              <div className="flex justify-start w-full h-12">
                <p className="text-left line-clamp-2">
                  <span className="font-semibold text-gray-500">
                    {
                      <span
                        dangerouslySetInnerHTML={{
                          __html: conversation.message,
                        }}
                      />
                    }
                  </span>
                </p>
              </div>
              <div className="flex flex-col w-1/6 justify-start items-end">
                {/* <div className="rounded-full h-4 w-4 bg-blue-400"></div> */}
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default ConversationCard;
