import React from "react";
import "moment-timezone";
import moment from "moment";
import GeneralCompanyImage from "../assets/images/company.png";
import { preSignFile } from "../service/blackbox-service";

const ChatBubble = ({ isSelf = false, ...rest }) => {
  function fileType(splitFile, isSelf, index) {
    if (splitFile === "jpeg" || splitFile === "jpg" || splitFile === "png") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7"
          viewBox="0 0 384 512"
        >
          <path
            fill={isSelf ? "white" : "rgba(24, 149, 255, var(--tw-bg-opacity))"}
            d="M224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM96 224c17.67 0 32 14.33 32 32S113.7 288 96 288S64 273.7 64 256S78.33 224 96 224zM318.1 439.5C315.3 444.8 309.9 448 304 448h-224c-5.9 0-11.32-3.248-14.11-8.451c-2.783-5.201-2.479-11.52 .7949-16.42l53.33-80C122.1 338.7 127.1 336 133.3 336s10.35 2.674 13.31 7.125L160 363.2l45.35-68.03C208.3 290.7 213.3 288 218.7 288s10.35 2.674 13.31 7.125l85.33 128C320.6 428 320.9 434.3 318.1 439.5zM256 0v128h128L256 0z"
          />
        </svg>
      );
    }
    return (
      <svg
        className="h-7 w-7"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="36"
        viewBox="0 0 27 36"
      >
        <path
          fill={isSelf ? "white" : "rgba(24, 149, 255, var(--tw-bg-opacity))"}
          id="Icon_awesome-file-alt"
          data-name="Icon awesome-file-alt"
          d="M15.75,9.563V0H1.688A1.683,1.683,0,0,0,0,1.688V34.313A1.683,1.683,0,0,0,1.688,36H25.313A1.683,1.683,0,0,0,27,34.313V11.25H17.438A1.692,1.692,0,0,1,15.75,9.563Zm4.5,16.594a.846.846,0,0,1-.844.844H7.594a.846.846,0,0,1-.844-.844v-.562a.846.846,0,0,1,.844-.844H19.406a.846.846,0,0,1,.844.844Zm0-4.5a.846.846,0,0,1-.844.844H7.594a.846.846,0,0,1-.844-.844v-.562a.846.846,0,0,1,.844-.844H19.406a.846.846,0,0,1,.844.844Zm0-5.062v.563a.846.846,0,0,1-.844.844H7.594a.846.846,0,0,1-.844-.844v-.562a.846.846,0,0,1,.844-.844H19.406A.846.846,0,0,1,20.25,16.594ZM27,8.571V9H18V0h.429a1.686,1.686,0,0,1,1.2.492l6.884,6.891A1.682,1.682,0,0,1,27,8.571Z"
        />
      </svg>
    );
  }
  function smsIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="15"
        viewBox="0 0 20.929 31.908"
      >
        <g
          id="Group_8418"
          data-name="Group 8418"
          transform="translate(5556 13469.996)"
        >
          <path
            id="Subtraction_20"
            data-name="Subtraction 20"
            d="M17.91,31.909H3.094a3.525,3.525,0,0,1-2.189-.723A2.188,2.188,0,0,1,0,29.479V2.344C0,1.051,1.388,0,3.094,0H17.919a3.362,3.362,0,0,1,2.39.9,2.01,2.01,0,0,1,.619,1.448V29.479A2.776,2.776,0,0,1,17.91,31.909Zm-7.15-3.446A1.041,1.041,0,1,0,11.8,29.5,1.042,1.042,0,0,0,10.76,28.462ZM1.682,3.632a.169.169,0,0,0-.186.142V27.208a.169.169,0,0,0,.186.142H19.256a.17.17,0,0,0,.187-.142V3.775a.17.17,0,0,0-.187-.142ZM9.067,1.638a.339.339,0,0,0-.373.285.339.339,0,0,0,.373.284h2.8a.339.339,0,0,0,.374-.284.339.339,0,0,0-.374-.285Z"
            transform="translate(-5556 -13469.997)"
            fill="currentColor"
          />
        </g>
      </svg>
    );
  }
  function emailIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke-width="1.5" 
        stroke="currentColor" 
        width="10"
        height="15"
        className="w-6 h-6"
        >
          <path 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" 
        />
      </svg>

    );
  }
  function toDateTime(secs) {
    let date = moment.utc(secs.seconds * 1000).format("MMM. DD, yyyy hh:mm a");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(date).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }
  const getRecepients = (data) => {
    let formatTo = "";
    const chunkSize = 2;
    const arrChunks = [];
    if (data !== undefined) {
      if (data.length > 0) {
        //split into 2's
        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            arrChunks.push(chunk)
        }

        formatTo = arrChunks.map((e) => {
          return `${e}, `;
        }).join('<br />')
      }
    }
    return formatTo;
  }

  async function openFile(_data) {
    const payload = {
      fileUrl: [decodeURI(_data)],
    };
    const result = await preSignFile(payload);

    if (result.status === 200) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = result.data.data[0];
      a.click();
    }
  }

  return (
    <div
      className={`flex flex-col w-full ${
        isSelf ? "pl-24 pr-4 md:pr-8" : "pl-4 pr-24"
      }`}
    >
      <div className={`flex flex-row ${isSelf ? "justify-end" : "gap-3"}`}>
        {!isSelf && (
          <div className="w-16 min-w-16 h-12 pt-6">
            <img
              className={`rounded-full w-12 h-12 shadow-sm border-black border`}
              src={GeneralCompanyImage}
              alt="A design"
            />
          </div>
        )}
        <div className="flex-auto">
          {!isSelf && <p className="flex text-md text-gray-400">{rest.name}</p>}
          <div
            className={`rounded-lg p-4 max-w-xs flex flex-col ${
              isSelf ? "bg-default" : "bg-gray-100"
            }`}
          >
            {rest.type === "SMS" && (
              <div className={`flex flex-row justify-between py-2 text-sm gap-1 ${isSelf ? "text-white" : ""}`}>
                <div className="w-1/12 text-left">To: </div>
                <div className="w-full text-left">
                <span
                    dangerouslySetInnerHTML={{
                      __html: getRecepients(rest.conversation.to),
                    }}
                  />
                  </div>
                <div className="w-1/12">{smsIcon()}</div>
              </div>
            )}
            {rest.type === "RECEIVE-SMS" && (
              // <div className={`text-md font-semibold ${isSelf ? "text-white" : "text-gray-400"}`}>
              //   <div className="flex justify-end text-right text-xs pt-2">{smsIcon()}</div>
              //   From: {rest.conversation.from}
              // </div>
              <div className={`flex flex-row justify-between py-2 text-sm font-semibold gap-1 ${isSelf ? "text-white" : "text-gray-400"}`}>
                <div className="w-1/6 text-left">From: </div>
                <div className="w-full text-left">{rest.conversation.from}</div>
                <div className="w-1/12">{smsIcon()}</div>
              </div>
            )}
            {rest.type === "EMAIL" && (
              <div className={`text-sm ${isSelf ? "text-white" : ""}`}>
                <div className="flex justify-end text-right text-xs pt-2">{emailIcon()}</div>
              </div>
            )}
            {!isSelf && (
              <div className="text-md text-gray-400 font-semibold">
                {rest.conversation.fullName}
              </div>
            )}
            {rest.type.includes('SMS') &&
              <hr />
            }
            <div
              className={`pt-2 text-sm break-words ${isSelf ? "text-white" : "text-gray-500"}`}
            >
              {rest.type === "EMAIL" ? (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: rest.conversation.message,
                    }}
                  />
                  {rest.conversation.attachments ? (
                    <div className="my-2">
                      {rest.conversation.attachments?.map((e, i) => (
                        <div
                          key={i}
                          className={`rounded-lg max-w-xs flex flex-col my-1 ${
                            isSelf
                              ? "bg-default text-white"
                              : "bg-gray-100 text-default"
                          }`}
                        >
                          <div className="w-full flex flex-row items-center gap-1">
                            <div className="w-10">
                              {fileType(e.fileType, isSelf, i)}
                            </div>
                            <div className="flex">
                              <a
                                className="underline cursor-pointer line-clamp-2 overflow-ellipsis text-sm font-light"
                                onClick={() => openFile(e.filePath)}
                              >
                                {e ? e.fileName : e.fileName}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                rest.conversation.message
              )}
            </div>
            {rest.type === "ATTACHMENT" && (
              <div className="my-2">
                {rest.conversation.attachments &&
                  rest.conversation.attachments.map((e, i) => (
                    <div
                      key={i}
                      className={`rounded-lg max-w-xs flex flex-col my-1 ${
                        isSelf
                          ? "bg-default text-white"
                          : "bg-gray-100 text-default"
                      }`}
                    >
                      <div className="w-full flex flex-row items-center gap-1">
                        <div className="w-10">
                          {fileType(e.fileType, isSelf, i)}
                        </div>
                        <div className="flex">
                          <a
                            className="underline cursor-pointer line-clamp-2 overflow-ellipsis text-sm font-light"
                            onClick={() => openFile(e.filePath)}
                          >
                            {e.fileName}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="flex flex-row justify-end">
              <p
                className={`text-xs  ${
                  isSelf ? "text-white" : "text-gray-400"
                }`}
              >
                {toDateTime(rest.timestamp)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
