import React from "react";
import { useHistory } from "react-router-dom";
import { FilesInfo } from "../components/Components";
import NoFileFound from "../assets/images/no-file-found.png";

const MainChatFiles = () => {
  let history = useHistory();

  function goToPreviousPage() {
    window.parent.postMessage("back to listing", "*");
    history.goBack();
  }

  return (
    <div className="min-h-screen min-w-full flex flex-col pt-8 gap-8">
      <div className="flex flex-row justify-between px-2">
        <div className="flex flex-col items-center justify-center">
          <svg
            onClick={() => goToPreviousPage()}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-secondary cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col text-center">
          <p className="text-md">{history.location.state.topicHeader}</p>
          <p className="text-xl font-semibold text-secondary cursor-pointer hover:underline">
            {history.location.state.topic}
          </p>
          <p className="text-xl font-semibold">
            {history.location.state.title}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center relative w-9"></div>
      </div>
      <div className="flex flex-row justify-center pl-8">
        <p className="text-lg">
          Files
        </p>
      </div>
      
      {
        history.location.state.files.length === 0 &&
        <div className="flex flex-col items-center flex-grow px-4 gap-4">
          <img src={NoFileFound} alt="No File" className="h-52" />
          <p className="text-xl font-bold text-gray-600 mt-2">Oops!</p>
          <p className="text-md text-gray-500">
            No File Found
          </p>
        </div>
      }
      <div className="flex flex-col flex-grow px-4 gap-4">
        {history.location.state.files.map((file) => (
          <FilesInfo
            key={file.fileKey}
            information={file}
          />
        ))}
      </div>
    </div>
  );
};

export default MainChatFiles;
