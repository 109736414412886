import React from "react";

const OriginDestinationDropDown = ({
  type,
  privateChat,
  setPrivateActive,
  isPrivateActive,
  tripName,
  setOrigin,
  isOrigin,
  isShowDrop,
  goToMembers,
  goToFiles,
  gotoTripPage,
  goToSearchConversation,
}) => {

  const isActive = () => {
    if (!isPrivateActive) {
      setOrigin(true);
      isShowDrop(false);
      setPrivateActive(true);
    }
  }

  return (
    <div
      // className={`absolute divide-y-2 right-0  mr-3 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 transition-all duration-500 ease-in-out cursor-pointer
      className={`absolute right-0  mr-3 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 transition-all duration-500 ease-in-out cursor-pointer
      ${
        type.toLowerCase() === "trucking"
          ? "mt-32"
          : type.toLowerCase() === "destination"
          ? "mt-44"
          : "mt-52"
      }
      `}
    >
      <div className="border-b-2">
        <p
          className={`px-4 py-2 text-sm  font-semibold cursor-pointer
              ${isOrigin ? "text-active" : "text-gray-700"}
              ${(type === "origin") || type === "bookingparty" ? "block" : "hidden"}
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-0"
          onClick={() => {
            setOrigin(true);
            isShowDrop(false);
            setPrivateActive(false)
          }}
        >
          Origin
        </p>
        <p
          className={` px-4 py-2 text-sm font-semibold cursor-pointer"
          ${isOrigin ? "text-gray-700" : "text-active"}
          ${
            type === "destination" || type === "bookingparty" ? "block" : "hidden"
          }
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => {
            setOrigin(false);
            isShowDrop(false);
            setPrivateActive(false)
          }}
        >
          Destination
        </p>
        <p
          className={` px-4 py-2 text-sm font-semibold cursor-pointer"
          ${(isPrivateActive) ? "text-active" : "text-gray-700"}
          ${
            privateChat ? "block" : "hidden"
          }
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => { isActive() }}
        >
          {privateChat}
        </p>
      </div>

      <div className="border-b-2">
        <p
          className={`px-4 py-2 text-sm font-semibold cursor-pointer text-gray-700"
          ${
            tripName
            ? "hidden"
            : type.toLowerCase() === "trucking"
            ? "block"
            : "hidden"
          }
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => gotoTripPage()}
          >
          Trip/s
        </p>
      </div>

      <div className="border-b-2">
        <p
          className={`block px-4 py-2 text-sm font-semibold cursor-pointer text-gray-700"
          
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => goToMembers()}
        >
          Members
        </p>
        <p
          className={`block px-4 py-2 text-sm font-semibold cursor-pointer text-gray-700"
          
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => goToFiles()}
        >
          Files
        </p>
        <p
          className={`block px-4 py-2 text-sm font-semibold cursor-pointer text-gray-700"
          `}
          role="menuitem"
          tabIndex="-1"
          id="menu-item-1"
          onClick={() => goToSearchConversation()}
        >
          Search in Conversation
        </p>
      </div>
    </div>
  );
};

export default OriginDestinationDropDown;
