import React from "react";
import GeneralCompanyImage from "../assets/images/company.png";

const MemberInfo = ({ information, ...rest }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <img
        className={`rounded-full shadow-sm border-2 border-gray-300 h-14 w-14`}
        src={information.imageUrl ? information.imageUrl : GeneralCompanyImage}
        alt="A design"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = GeneralCompanyImage;
        }}
      />
      <p className="text-sm font-semibold">
        {information.name} {rest.currentUser === information.guid ? "(Me)" : ""}
      </p>
    </div>
  );
};

export default MemberInfo;
