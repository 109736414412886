import React from "react";
import "moment-timezone";
import moment from "moment";

const ViewConvoBubble = ({ isSelf, ...rest }) => {
  function toDateTime(secs) {
    let date = moment.utc(secs.seconds * 1000).format("MMM. DD, yyyy hh:mm a");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(date).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }

  return (
    <div
      className={`flex flex-col w-full ${
        isSelf ? "pl-24 pr-4 md:pr-8" : "pl-4 pr-24"
      }`}
    >
      {isSelf}
      <div className={`flex flex-row ${isSelf ? "justify-end" : "gap-3"}`}>
        <div>
          <div
            className={`rounded-lg p-4 max-w-xs flex flex-col ${
              isSelf ? "bg-default" : "bg-gray-100"
            }`}
          >
            {!isSelf && (
              <p className="text-sm text-black font-bold mb-1">{rest.name}</p>
            )}
            {!isSelf && (
              <p className="text-md text-gray-400 font-semibold">
                {rest.conversation.fullName}
              </p>
            )}
            <p className={`text-sm ${isSelf ? "text-white" : ""}`}>
              {
                <span
                  dangerouslySetInnerHTML={{
                    __html: rest.conversation.message,
                  }}
                />
              }
            </p>
            <div className="flex flex-row justify-end">
              <p
                className={`text-xs  ${
                  isSelf ? "text-white" : "text-gray-400"
                }`}
              >
                {toDateTime(rest.timestamp)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewConvoBubble;
