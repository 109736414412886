import React from "react";

function goToTripDetails(data) {
  const message = {
    message: "details",
    details: data,
    description: "Trip",
  };

  window.parent.postMessage(message, "*");
}

const TripInfo = ({ information, ...rest }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <p
        className="text-sm font-semibold text-secondary cursor-pointer"
        onClick={() => goToTripDetails(information.jobOrderNoTripNo)}
      >
        {information.jobOrderNoTripNo}
      </p>
    </div>
  );
};

export default TripInfo;
