import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getListOfChannels } from "../service/blackbox-service";
import { getPresignedImage } from "../service/global-service";
import moment from "moment";
import GeneralCompanyImage from "../assets/images/company.png";

const ThreadCard = ({
  setIsLoading,
  topic,
  memberId,
  isBookingParty,
  token,
  listingType,
}) => {
  let history = useHistory();

  const [topicInformation, setTopicInformation] = useState({});

  const { search } = useLocation();

  useEffect(() => {
    if (topic) {
      const { information } = topic;
      setTopicInformation(information);
    }
  }, [topic]);

  // Get Presigned
  const getImage = async (image) => {
    let result = await getPresignedImage({
      token: token,
      image: image,
    });

    if (result.status === 200) {
      const {
        data: {
          data: { presigneUrl },
        },
      } = result;

      return presigneUrl;
    } else {
      return GeneralCompanyImage;
    }
  };

  const goToChatPage = async () => {
    setIsLoading(true);
    const query = new URLSearchParams(search);
    let payload = {
      memberId: query.get("companyId"),
      topic: topic.name,
    };
    if (query.get("roleAs")) {
      payload["roleAs"] = query.get("roleAs");
    }

    let result = await getListOfChannels(payload);

    try {
      if (result.status === 200) {
        setIsLoading(false);
        const {
          data: { data: channels },
        } = result;

        let allChat = channels.find(
          (channel) => channel.information.chatHeads === "All"
        );
        let mappedChannels = channels.map((channel) => {
          return {
            header: channel.channel,
            notification: 0,
            isActive: false,
            chatHeader: "",
            serviceHeader: "",
            ...channel,
          };
        });

        mappedChannels[0].isActive = true;

        new Promise((resolve, reject) => {
          mappedChannels.forEach((mc, index) => {
            mc.members.forEach(async (mmc, idx) => {
              mmc.data.imageUrl = await getImage(mmc.data.imageUrl);
            });

            if (index === mappedChannels.length - 1) {
              resolve();
            }
          });
        });
        const message = {
          message: "details",
          details: topic.name,
          serviceType: topic.information.serviceType,
          parentId: topic.information.parentId,
          description: topic.information.description,
          members: mappedChannels,
          roleAs: query.get("roleAs"),
        };

        window.parent.postMessage(message, "*");

        history.push({
          pathname: "/chat",
          state: {
            channels: mappedChannels,
            memberId: memberId,
            token,
            topic: topic.name,
            isBookingParty: isBookingParty,
            companyName: query.get("companyName"),
            reservationId: query.get("reservationId"),
            tripId: query.get("tripId"),
            fullName: query.get("fullName"),
            userName: query.get("userName"),
            userId: query.get("userId"),
            roleAs: query.get("roleAs"),
            isAgency: query.get("isAgency"),
            createdOn: query.get("createdOn"),
            associate: query.get("associate"),
            isSameSC: query.get("isSameSC"),
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.warn(error);
    }
  };

  const processTopicName = () => {
    if (listingType === "Trip Ticket Channel") {
      return ``;
    }
    return topic.name;
  };

  const processDate = (date) => {
    let newDate;
    var formats = {
      sameDay: "LT",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd [at] LT",
      lastDay: "[Yesterday], LT",
      lastWeek: "[Last] dddd",
      sameElse: "MMM. DD, yyyy hh:mm a",
    };
    if (date) {
      // const timeStamp = new Date().getTime();
      // const yesterdayTimeStamp = timeStamp - 68 * 60 * 60 * 1000;
      // newDate = new Date(yesterdayTimeStamp);
      newDate = date;
    } else {
      newDate = new Date();
    }
    return moment(new Date(newDate)).calendar(null, formats);
  };

  return (
    <>
      <div className="px-2 cursor-pointer" onClick={() => goToChatPage()}>
        <div className="w-auto border-t-2 py-4 px-2 flex flex-col">
          <div className="flex flex-row items-start justify-between">
            <div className="text-xl font-bold text-primary">
              {listingType === "Trip Ticket Channel" ||
              listingType === "Service Level Channel,Service level Channel" ? (
                <div>
                  <div>{topic.information.parentId}</div>
                  <div>{topic.name}</div>
                </div>
              ) : (
                <div>{topic.name}</div>
              )}
            </div>
            <p className="text-xs text-gray-400">
              {processDate(topicInformation?.lastMessage?.timestamp)}
            </p>
          </div>
          <div className="flex flex-row">
            <div className="flex justify-start w-full h-12">
              <p className="text-left line-clamp-2">
                <span className="font-bold text-primary">
                  {topicInformation?.lastMessage?.type?.includes("NOTIFICATION")
                    ? topicInformation?.lastMessage?.type
                    : topicInformation?.lastMessage?.sender?.userName
                    ? topicInformation?.lastMessage?.sender?.userName
                    : topicInformation?.lastMessage?.senderName}
                </span>
                {" : "}
                <span className="font-semibold text-gray-500">
                  {topicInformation?.lastMessage?.message || "N / A"}
                </span>
              </p>
            </div>
            <div className="flex flex-col w-1/6 justify-start items-end">
              <div className="rounded-full h-4 w-4 bg-blue-400"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreadCard;
