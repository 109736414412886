import React from "react";
import { useHistory } from "react-router-dom";
import ViewConvoBubble from "../components/ViewConvoBubble";
import moment from "moment";

const MainChatConvoView = () => {
  let history = useHistory();

  const goToPreviousPage = () => {
    history.goBack();
  };

  function toDateTime(secs) {
    let date = moment.utc(secs.seconds * 1000).format("MMM. DD, yyyy hh:mm a");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(date).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }

  return (
    <div className="min-h-screen min-w-full flex flex-col pt-8">
      <div className="flex flex-row justify-between items-center px-2">
        <div className="flex flex-col items-center justify-center">
          <svg
            onClick={() => goToPreviousPage()}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-secondary cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col text-center">
          <p className="text-md"></p>
          <p className="text-2xl font-semibold text-secondary cursor-pointer hover:underline">
            {history.location.state.title}
          </p>
        </div>
        <div className="w-10"></div>
      </div>
      <div className="flex flex-row items-center justify-center mt-4">
        <p className="text-sm font-medium text-gray-400">
          {toDateTime(history.location.state.chat.timestamp)}
        </p>
      </div>
      <div className="flex-grow h-full w-full mt-5 overflow-hidden relative">
        <ViewConvoBubble
          {...history.location.state.chat}
          isSelf={
            history.location.state.chat.conversation.userId ===
            history.location.state.userId
          }
        />
      </div>
    </div>
  );
};

export default MainChatConvoView;
