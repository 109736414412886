import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SearchInput } from "../components/Components";
import debounce from "lodash.debounce";
import { ConversationCard } from "../components/Components";

const MainChatSearchConvo = () => {
  let history = useHistory();

  const [chatList, setChatList] = useState([
    ...history.location.state.chatList,
  ]);

  const [tempChatList] = useState([...history.location.state.chatList]);

  const userId = useRef(history.location.state.userId);

  const goToPreviousPage = () => {
    history.goBack();
  };

  const searchArray = (value) => {
    let mappedResult = tempChatList.filter((chat) =>
      chat.conversation.message.toLowerCase().includes(value.toLowerCase())
    );

    if (mappedResult.length) {
      mappedResult = mappedResult.map((chat) => {
        let message = chat.conversation.message;

        let pos = message.search(new RegExp(value, "i"));
        let lastPosition = pos + value.length - 1;

        let modifiedMessage;

        if (pos !== -1 && lastPosition !== -1) {
          modifiedMessage = message.replace(
            new RegExp(value, "i"),
            `<b>${message.substring(pos, lastPosition + 1)}</b>`
          );
        } else {
          modifiedMessage = message;
        }

        return {
          ...chat,
          conversation: {
            ...chat.conversation,
            message: modifiedMessage,
          },
        };
      });
    }

    setChatList([...mappedResult]);
  };

  const delayInput = debounce(searchArray, 500);

  const viewChat = (chat) => {
    history.push({
      pathname: `/chat/${history.location.state.activeChat}/search/view`,
      state: {
        currentUser: history.location.state.currentUser,
        topic: history.location.state.topic,
        title: history.location.state.title,
        userId: history.location.state.userId,
        chat,
      },
    });
  };

  return (
    <div className="min-h-screen min-w-full flex flex-col pt-8">
      <div className="flex flex-row justify-between items-center px-2">
        <div className="flex flex-col items-center justify-center">
          <svg
            onClick={() => goToPreviousPage()}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-secondary cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col text-center">
          <p className="text-md"></p>
          <p className="text-2xl font-semibold text-secondary cursor-pointer hover:underline">
            {history.location.state.title}
          </p>
        </div>
        <div className="w-10"></div>
      </div>
      <div className="w-full py-2 px-4 my-4 flex flex-row justify-between items-center gap-4">
        <SearchInput onChange={delayInput} />
      </div>
      <div className="flex-grow h-full w-full overflow-hidden relative">
        <div className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto">
          {chatList.length !== 0 ? (
            chatList.map((chat) => (
              <ConversationCard
                {...chat}
                key={chat.timestamp}
                currentUser={userId.current}
                viewChat={viewChat}
              />
            ))
          ) : (
            <>No Data Found</>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainChatSearchConvo;
