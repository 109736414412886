import axios from "axios";

export const getListOfTopics = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/topic/collection`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const getListOfChannels = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/channel/collection`,
    payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const getListOfFiles = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/channel/collection`,
    payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const sendChat = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/send/message`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const sendAttachment = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/send/attachment`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const sendEmail = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/send/email`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const preSignFile = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/presign`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};

export const sendSms = (payload) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/send/sms`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'x-api-key': process.env.REACT_APP_BASE_URL_API_KEY
      },
    }
  );
};
