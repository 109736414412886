import React from "react";
import "moment-timezone";
import moment from "moment";
import { preSignFile } from "../service/blackbox-service";

const FilesInfo = ({ information }) => {
  
  function toDateTime(secs) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(secs).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }
  function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
  }
  async function openFile(_data) {
    const payload = {
      fileUrl: [decodeURI(_data)],
    };

    const result = await preSignFile(payload);

    if (result.status === 200) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = result.data.data[0];
      a.click();
    }
  }
  return (
    <div className="flex flex-row items-center gap-2 bg-gray-200 h-18">
      <div className="flex flex-col justify-start 1/4 bg-blue-600 text-white text-2xl p-5 h-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z" clipRule="evenodd" />
          <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
        </svg>
      </div>
      <div className="flex flex-col justify-start w-1/2">
      <h4><strong>{information?.fileName}</strong></h4>
      <p className="text-xs text-gray">{humanFileSize(information?.fileSize)}</p>
      <p className="text-xs text-gray">{toDateTime(information?.uploadedAt)}</p>
      </div>
      <div className="flex flex-col w-1/4 justify-start items-end text-blue-600" onClick={() => openFile(information.filePath)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  );
};

export default FilesInfo;
