import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import MainChatConvoView from "./pages/MainChatConvoView";
import MainChatList from "./pages/MainChatList";
import MainChatMembers from "./pages/MainChatMembers";
import MainChatFiles from "./pages/MainChatFiles";
import MainChatPage from "./pages/MainChatPage";
import MainChatSearchConvo from "./pages/MainChatSearchConvo";
import MainTripInfo from "./pages/MainTripInfo";

function App() {
  return (
    <div className="App">
      <Router>
        <header></header>
        <main className="max-w-xl mx-auto">
          <Switch>
            <Route exact path="/">
              <MainChatList />
            </Route>
            <Route
              path="/chat"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}/`} component={MainChatPage} exact />
                  <Route
                    path={`${url}/:chatId/members`}
                    component={MainChatMembers}
                    exact
                  />
                  <Route
                    path={`${url}/:chatId/files`}
                    component={MainChatFiles}
                    exact
                  />
                  <Route
                    path={`${url}/:chatId/trips`}
                    component={MainTripInfo}
                    exact
                  />
                  <Route
                    path={`${url}/:chatId/search`}
                    component={MainChatSearchConvo}
                    exact
                  />
                  <Route
                    path={`${url}/:chatId/search/view`}
                    component={MainChatConvoView}
                    exact
                  />
                </>
              )}
            />
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
