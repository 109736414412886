import React, { useState, useRef } from "react";
import { FileBubble } from "../components/Components";

const UploadFile = ({ setAttachments }) => {
  const [files, setFiles] = useState([]);
  const [errormsg, setErrorMsg] = useState("")

  const fileAttachment = useRef();

  const handleFileChange = async (event) => {
    validateSingleFile(event)
  };

  async function validateSingleFile(event) {
    
    let tempFiles = [...files]
    if(event.target.files.length === 1){
      let singleSizeFile = validateFile(event.target.files[0])
      if(singleSizeFile <= 3.5) {
        let size = validateFileTotal(files,event.target.files[0])
        if (size > 3.5) {
            setErrorMsg("Once or more files not attached. Total attachment limit is 3.5 MB")
        }else {
          tempFiles = [...tempFiles, event.target.files[0]];
          setErrorMsg("")
        }
      } else {
        setErrorMsg(`The file/s you are trying to send exceed the 3.5 MB total attachment limit.`)
      }
    }  else {
      Array.prototype.forEach.call(event.target.files, function(item) {
        if(item.size/1024/1024 <= 3.5) {
              let size = validateFileTotal(tempFiles, item)
              if (size > 3.5) {
                setErrorMsg("Once or more files not attached. Total attachment limit is 3.5 MB")
              }else {
                let fileChecker = validateTotalWithNewArrayFile(event.target.files)
                tempFiles = [...tempFiles, item];
                  if(fileChecker >= 3.5) {
                    setErrorMsg("Once or more files not attached. Total attachment limit is 3.5 MB")
                  } else {
                    setErrorMsg("")
                  }
              }
            }else {
              setErrorMsg("Once or more files not attached. Total attachment limit is 3.5 MB")
            }
      })
    }
    await setFiles([...tempFiles]);
    await setAttachments([...tempFiles])
   
  }

  function validateFile(item) {
    return item.size/1024/1024;
  }
    
  function validateFileTotal(files, item?) {
    let newFiles
    if(item) {
       newFiles= [...files, item]
    } else {
      newFiles= [...files]
    }
    
    if(files.length !== 0) {
      let size = newFiles.reduce((a , b) => a + b.size, 0)
      return size/1024/1024;
    }
  }

  
 function validateTotalWithNewArrayFile(items) {
  let newArray = [...files]
  if(items.length > 1) {
    for(const item of items) {
      newArray = [...newArray,item]
    }
  }

  return validateFileTotal(newArray)
}



  const deleteFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setAttachments([...tempFiles]);
    setFiles([...tempFiles]);
    setErrorMsg("")
  };

 

  return (
    <div className="flex flex-col bg-gray-100">
 <div className=" border-t px-4 pt-4 flex flex-row gap-4 overflow-y-scroll h-22 items-center">
      {files.length !== 0 &&
        files.map((file, index) => (
          <FileBubble
            key={index}
            fileName={file.name}
            deleteFile={deleteFile}
            index={index}
          />
        ))}
      {/* <FileBubble fileName="asdla ksdlka sldk asl.docx" />
      <FileBubble fileName="asdlaks dl kasl dka sl.docx" />
      <FileBubble fileName="asdl aksdlk as ldka sl.docx" />
      <FileBubble fileName="1231.docx" />
      <FileBubble fileName="435 46.docx" />
      <FileBubble fileName="xxz c2 32.docx" /> */}
      <button
        className="py-2 px-4 rounded-md bg-gray-300 text-sm text-gray-700"
        onClick={() => {
          fileAttachment.current.click();
        }}
      >
        Add File
      </button>
      <input
        type="file"
        ref={fileAttachment}
        onChange={handleFileChange}
        className="hidden"
        multiple
      />
    </div>
    {errormsg ? 
    <div className="px-4 text-red-400 pt-1 text-sm">
    {errormsg}
    </div> :
    <></>
    }
    
    </div>
   
  );
};

export default UploadFile;
