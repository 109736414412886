import firebase from "firebase/compat/app";
import {signInAnonymously, getAuth} from "firebase/auth";
import "firebase/compat/firestore";

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  console.log("Initialize!");
  var app = firebase.initializeApp(clientCredentials);

  const auth = getAuth(app)
  signInAnonymously(auth).then(() => {
    // sign in
  })
  .catch((error) => {

  })
}

export default firebase;
