import axios from "axios";

export const getPresignedImage = ({ token, image }) => {
    return axios.post(
        `${process.env.REACT_APP_GLOBAL_URL}/global/cms/api/v1/file/pre-signed/single`,
        image,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${token}`
          },
          
        }
      );
  };