import React, { useRef, useEffect, useState } from "react";
import { NumberChips } from "../components/Components";

const SmsForm = ({ cancelFormEvent, sendSmsEvent, activeChatHead, senderId}) => {
  const [number, setNumber] = useState([]);
  const [defaultNumbers, setDefaultNumbers] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const message = useRef("");
  function getNumberField(payload) {
    setNumber(payload);
    if(!!message.current.value && payload.length > 0){
      setIsValid(true);
    }else{
      setIsValid(false);
    }
  }
  const sendSms = async () => {
    if(number.length === 0){
      window.alert("Number is required");
    }else if(message.current.value === ""){
      window.alert("Message is required");
    }else{
      let info = { sender: senderId, numbers: number, message: message.current.value, index: 1};
      sendSmsEvent(info);
    }
  };
  const messageBlur = (field) => {
    if (!!field && number.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  useEffect(() => {
    setDefaultNumbers(
      [...new Set(activeChatHead.members.filter(e => e.id !== senderId).map(({data})=> data.mobileNo))] || []
    );
    setNumber(
      [...new Set(activeChatHead.members.filter(e => e.id !== senderId).map(({data})=> data.mobileNo))] || []
    );
  }, [activeChatHead, senderId]);

  return (
    <div className="bg-gray-100 border-t p-4 flex flex-col gap-4">
      <NumberChips defaultNumbers={defaultNumbers} 
        getNumberField={getNumberField} placeholder="+639171234567"/>
      <textarea
        ref={message}
        className="bg-white rounded-md w-full py-2 pr-4 pl-2 text-black leading-tight focus:outline-none resize-none border"
        type="text"
        placeholder="Type Message Here"
        onChange={() => messageBlur(message.current.value)}
        minLength="1"
        maxLength="120"
      />
      <div className="flex flex-row justify-end items-center">
        <div className="flex flex-row gap-2">
          <button
            onClick={() => cancelFormEvent(1,true)}
            className="h-9 w-20 rounded-md py-1 bg-gray-300 border text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => sendSms()}
            className={`${
              isValid ? "bg-active" : "bg-gray-300"
            } h-9 w-20 rounded-md py-1 border text-white`}
            disabled={isValid ? false : true}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default SmsForm;
