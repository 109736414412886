import React from "react";
import "moment-timezone";
import moment from "moment";

const NotificationBubble = ({ topicName, hasAttachment = false, ...rest }) => {
  function toDateTime(secs) {
    let date = moment.utc(secs.seconds * 1000).format("MMM. DD, yyyy hh:mm a");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(date).tz(timezone).format("MMM. DD, yyyy hh:mm a");
  }
  return (
    <div className="flex flex-row rounded-lg shadow-sm mx-7">
      <div
        className={`flex flex-col w-20 p-1 ${
          rest.conversation?.isEmergency ? "bg-danger" : rest.conversation?.description === "COMPLETED" ? "bg-success" :"bg-default"
        } rounded-tl-3xl rounded-bl-3xl items-center justify-center`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill={rest.conversation?.isEmergency ? "" : "currentColor"} 
            fillRule="evenodd"
            clipRule="evenodd"
            d={
              rest.conversation?.isEmergency
                ? "M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                : "M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
            }
          />
        </svg>
      </div>
      <div className="flex flex-col p-4 border rounded-tr-3xl rounded-br-3xl gap-2 w-96">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <p className="text-sm leading-snug"> {rest.conversation.title}</p>
            <p
              className={`text-md leading-snug font-extrabold ${
                rest.conversation?.isEmergency ? "text-danger" : rest.conversation?.description === "COMPLETED" ? "text-success" : "text-default"
              }`}
            >
              {rest.conversation.description}
            </p>

            <p className="text-sm leading-snug">
              {rest.conversation?.reservationId
                ? rest.conversation?.jobOrderNoTricketId
                  ? rest.conversation?.serviceTicketId +
                    ">" +
                    rest.conversation?.jobOrderNoTricketId
                  : rest.conversation?.reservationId +
                    ">" +
                    rest.conversation?.serviceTicketId
                : topicName}
            </p>
          </div>
          <div className="flex flex-col w-12 items-center justify-center">
            {hasAttachment && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.221"
                height="19.681"
                viewBox="0 0 17.221 19.681"
              >
                <path
                  id="Icon_awesome-paperclip"
                  data-name="Icon awesome-paperclip"
                  d="M1.662,17.918a6.022,6.022,0,0,1,.053-8.363L9.778,1.307a4.3,4.3,0,0,1,6.176,0,4.474,4.474,0,0,1,0,6.238L8.926,14.727a2.868,2.868,0,0,1-4.151-.038,2.979,2.979,0,0,1,.056-4.111l5.525-5.644a.615.615,0,0,1,.87-.009l.879.86a.615.615,0,0,1,.009.87L6.589,12.3a.52.52,0,0,0-.025.7.41.41,0,0,0,.6.006L14.2,5.825a2.013,2.013,0,0,0,0-2.8,1.844,1.844,0,0,0-2.658,0L3.474,11.274a3.56,3.56,0,0,0-.046,4.932,3.278,3.278,0,0,0,4.73.011l6.614-6.765a.615.615,0,0,1,.87-.01l.879.86a.615.615,0,0,1,.01.87L9.918,17.936a5.735,5.735,0,0,1-8.255-.019Z"
                  fill="#cacbce"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="text-left line-clamp-4 w-full">
          <div className="leading-tight w-80 text-sm">
            <span
              dangerouslySetInnerHTML={{
                __html: rest.conversation.message
              }}
            />
          </div>
        </div>
        <p className="text-right text-xs pt-2">{toDateTime(rest.timestamp)}</p>
      </div>
    </div>
  );
};

export default NotificationBubble;
